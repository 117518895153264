import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import { parse } from 'querystring';
import { useScrollRestoration } from 'gatsby';
import useFilters from '../components/Filters/useFilters';

import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import Map from '../components/map/Map';
import MapPropertyLayout from '../components/layouts/MapPropertyLayout';
import PropertyCard from '../components/Properties/PropertyCard';
import Filters from '../components/Filters/Filters';
import Pagination from '../components/Common/Pagination';

import { fetchProperties, resetProperties } from '@a1-ict/fsbo/propertySlice';
import { selectProperties } from '@a1-ict/fsbo/propertySlice/selectors';
import { hideLoader, showLoader } from '@a1-ict/core/dialogSlice';
import _ from 'lodash';

function formatNumber(x) {
  return Math.round(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

const BuyView = () => {
  const [hoveredProperty, setHoveredProperty] = useState(null);
  const [initialPage, setInitialPage] = useState(0);
  const [isListView, setIsListView] = useState('all');
  const [isSearchByBoundsActive, setIsSearchByBoundsActive] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const ulScrollRestoration = useScrollRestoration(`property-list`);

  const dispatch = useDispatch();
  const {
    items: locations,
    total_items,
    total_pages,
    average_prices,
    average_square_prices,
    location: searchLocation,
  } = useSelector(selectProperties);

  const breakpoints = useBreakpoint();
  const location = useLocation();
  const search = parse(location.search.substring(1));

  const filter = useFilters();
  const filterData = filter.getSearchParams();

  useEffect(() => {
    if (filterData.CurrentPage) {
      setInitialPage(filterData.CurrentPage - 1);
      filter.search();
    } else {
      setInitialPage(0);
    }
  }, [filterData.CurrentPage]);

  useEffect(() => {
    if (search.hasOwnProperty('UpperRightLongitude'))
      dispatch(fetchProperties({ queryParams: { ...search } }));
  }, [location.search]);

  useEffect(() => {
    // if (searchLocation) setHoveredProperty({ id: 0, cordinates: { ...searchLocation }, zoom: 14 });
    if (
      searchLocation &&
      hoveredProperty?.cordinates &&
      !_.isEqual(searchLocation, hoveredProperty.cordinates)
    )
      setHoveredProperty({ id: 0, cordinates: { ...searchLocation }, zoom: 14 });
  }, [searchLocation]);

  const handlePropertyHovered = (property) => {
    setHoveredProperty(property);
  };

  const handlePageClick = (data) => {
    filter.setSearchParams({ ...filterData, CurrentPage: data.selected + 1 });
  };

  const handleSearchByBounds = (boundaries, centre) => {
    dispatch(hideLoader());
    let filterDataCopy = { ...filterData };
    filterDataCopy.UpperRightLongitude = boundaries?.getNorthEast().lng();
    filterDataCopy.UpperRightLatitude = boundaries?.getNorthEast().lat();
    filterDataCopy.LowerLeftLongitude = boundaries?.getSouthWest().lng();
    filterDataCopy.LowerLeftLatitude = boundaries?.getSouthWest().lat();
    delete filterDataCopy.CityId;
    delete filterDataCopy.NeighborhoodId;
    delete filterDataCopy.DistrictId;
    // delete filterDataCopy.CentreLatitude;
    // delete filterDataCopy.CentreLongitude;
    delete filterDataCopy.Radius;
    filter.setSearchParams({ ...filterDataCopy, ...centre });
    // filter.search();
  };

  const setMapSettings = (params) => {
    const { CentreLongitude, CentreLatitude, Radius } = params;
    setTriggerSearch(true);
    setHoveredProperty({
      id: 0,
      cordinates: { lat: CentreLatitude, lng: CentreLongitude },
      zoom: 14,
      radius: Radius,
    });
  };

  useEffect(() => {
    if (breakpoints.sm) {
      setIsListView('map');
    } else {
      setIsListView('all');
    }
  }, [breakpoints.sm]);

  const setBoundsSearchActive = (isActive) => {
    setIsSearchByBoundsActive(isActive);
  };

  return (
    <>
      <MapPropertyLayout>
        <div className=" map-content_container w-100">
          <div className="map-content_container_filters p-2 pt-3 mb-2">
            <Filters
              setSearchByBounds={setIsSearchByBoundsActive}
              setMapSettings={setMapSettings}
            ></Filters>
          </div>

          <div
            style={{ opacity: isListView === 'list' ? 0 : 1 }}
            className="map-content_container_map h-100"
          >
            <Map
              showLoader={(bool) => {
                if (bool) dispatch(showLoader());
                else dispatch(hideLoader());
              }}
              readOnly={isListView === 'list' ? true : false}
              boundSearchOption={true}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              handleSearchByBounds={handleSearchByBounds}
              setBoundsSearchActive={setBoundsSearchActive}
              isSearchByBoundsActive={isSearchByBoundsActive}
              locations={locations.map((loc) => {
                if (loc.cordinates.lng && loc.cordinates.lat) {
                  return loc;
                } else {
                  return { ...loc, cordinates: { lat: 42.6977, lng: 23.3219 } };
                }
              })}
              resetProperties={() => {
                dispatch(resetProperties());
              }}
              hoveredLocation={hoveredProperty}
              preserveOnNavigate={true}
              readOnlyInfoWindow={false}
              predefinedCoords={
                filterData.CentreLatitude
                  ? {
                      lat: filterData.CentreLatitude,
                      lng: filterData.CentreLongitude,
                      zoomLevel: filterData.zoomLevel || null,
                      radius: filterData.Radius ?? 2,
                    }
                  : search.CentreLatitude
                  ? {
                      lat: Number(search.CentreLatitude),
                      lng: Number(search.CentreLongitude),
                      zoomLevel: Number(search.zoomLevel),
                    }
                  : null
              }
            ></Map>
          </div>

          {isListView !== 'map' && (
            <div className="map-content_container_content h-100" {...ulScrollRestoration}>
              <div className="mb-3">
                {!!total_items ? (
                  <>
                    <h5 style={{ textAlign: 'center' }}>Резултати: {total_items}</h5>
                    <span className="avg-prices">
                      Средна цена на имотите в извадката:{' '}
                      <span className="avg-prices_value">{formatNumber(average_prices)} EUR</span>
                    </span>
                    <span className="avg-prices">
                      Средна цена на кв.м.:{' '}
                      <span className="avg-prices_value">
                        {formatNumber(average_square_prices)} EUR
                      </span>
                    </span>
                    {total_pages > 1 && (
                      <div className="pt-2  d-flex justify-content-center align-items-center ">
                        <Pagination
                          handlePageClick={handlePageClick}
                          pageCount={total_pages}
                          forcePage={initialPage}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="mt-3">
                    <h5 style={{ textAlign: 'center' }}>Няма намерени резултати</h5>
                  </div>
                )}
                <div>
                  {locations
                    .map((property) => {
                      if (property.cordinates.lng && property.cordinates.lat) {
                        return property;
                      } else {
                        return { ...property, cordinates: { lat: 42.6977, lng: 23.3219 } };
                      }
                    })
                    .map((property, index) => {
                      return (
                        <div
                          key={property.id}
                          onMouseOver={() => {
                            handlePropertyHovered(property);
                          }}
                          onFocus={() => {
                            handlePropertyHovered(property);
                          }}
                          role="button"
                          tabIndex={index}
                        >
                          <PropertyCard property={property} variant="horizontal" isSearchResult />
                        </div>
                      );
                    })}
                </div>
                {total_pages > 1 && (
                  <div className="pt-2  d-flex justify-content-center align-items-center ">
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCount={total_pages}
                      forcePage={initialPage}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {breakpoints.sm && (
            <div className="map-content_container_buttons">
              <button
                onClick={() => setIsListView('list')}
                className={`${isListView === 'list' && 'active'}`}
              >
                Обяви
              </button>
              <button
                onClick={() => setIsListView('map')}
                className={`${isListView === 'map' && 'active'}`}
              >
                Карта
              </button>
            </div>
          )}
        </div>
      </MapPropertyLayout>
    </>
  );
};

export default BuyView;
