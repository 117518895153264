import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useGoogleMap } from "@react-google-maps/api";

const MapControl = ({ children, position, title = "" }) => {
  const map = useGoogleMap();
  let controlDiv = document.createElement("div");
  controlDiv.title = title;
  map.controls[position].push(controlDiv);

  useEffect(() => {
    const divIndex = map.controls[position].indexOf(controlDiv);
    return () => {
      map.controls[position].removeAt(divIndex);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, controlDiv);
};

export default MapControl;
