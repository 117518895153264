import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';

import useFilters from './useFilters';

import Category from './Fields/Category';
import ListedUnder from './Fields/ListedUnder';
import Price from './Fields/Price';

import { fetchFiltersSpecific, removeFiltersSpecific } from '@a1-ict/fsbo/searchSlice';
import SearchFormLocation from './Fields/SearchFormLocation';
import ShowMoreFilters from './Fields/ShowMoreFilters';

import PricePerSquareMeter from './Fields/PricePerSquareMeter';

const Filters = ({ autoSearch = true, setSearchByBounds, setMapSettings }) => {
  const filter = useFilters();
  const locationState = filter.getState()?.resetFilter;
  const filterData = filter.getSearchParams();
  const filtersToMaintain = [
    'radius',
    'address',
    'listedUnder',
    'IsBuilding',
    'UpperRightLongitude',
    'UpperRightLatitude',
    'LowerLeftLongitude',
    'LowerLeftLatitude',
  ]; //, 'priceLte', 'priceGte'

  let categoryIds = filterData.Categories;
  const listedUnder = filterData.listedUnder;
  if (categoryIds && typeof categoryIds !== 'object') {
    categoryIds = [categoryIds];
  }
  const dispatch = useDispatch();

  useDeepEffect(() => {
    if (categoryIds && listedUnder) {
      let queryString = 'Property/filters/specific?';

      if (typeof categoryIds === 'object') {
        queryString += categoryIds.map((x) => `CategoryIds=${x}&`).join('');
      } else {
        queryString += `CategoryIds=${categoryIds}`;
      }
      if (listedUnder) queryString += `listedUnder=${listedUnder}`;

      dispatch(fetchFiltersSpecific({ queryString }));
    } else {
      dispatch(removeFiltersSpecific());
    }
  }, [categoryIds, listedUnder]);

  const handleFilters = (data, removeFilters = false) => {
    if (removeFilters) {
      const copyOfFilters = Object.keys(filterData).reduce((obj, k) => {
        if (filtersToMaintain.includes(k)) obj[k] = filterData[k];
        return obj;
      }, {});
      filter.setSearchParams({ ...copyOfFilters });
    } else {
      filter.setSearchParams({ ...filterData, ...data, CurrentPage: 1 });
    }
  };

  // const handleSearch = () => {
  //   const id = Math.floor(Math.random() * (5000 - 1 + 1)) + 1;
  //   let filterDataCopy = { ...filterData };
  //   if (filterData.hasOwnProperty('CityId') && filterData.hasOwnProperty('UpperRightLongitude')) {
  //     setSearchByBounds(false);
  //     delete filterDataCopy.UpperRightLongitude;
  //     delete filterDataCopy.UpperRightLatitude;
  //     delete filterDataCopy.LowerLeftLongitude;
  //     delete filterDataCopy.LowerLeftLatitude;
  //   }
  //   filter.setSearchParams({ ...filterDataCopy, CurrentPage: 1 });
  //   // dispatch(saveLastSearches({ filters: { ...filterDataCopy, id: id }, maxElements: 15 }));
  //   filter.search();
  // };

  useEffect(() => {
    const initialFilters = filter.parse();
    handleFilters(initialFilters);

    if (locationState !== undefined) {
      filter.setSearchParams({ ...initialFilters });
    }
  }, [locationState]);

  useDeepEffect(() => {
    if (autoSearch && filterData.hasOwnProperty('LowerLeftLatitude')) filter.search({ delay: 700 });
  }, [filterData]);

  return (
    <div className="filters filters_wrapper ">
      <div className="filters_fields">
        <SearchFormLocation
          setMapSettings={setMapSettings}
          setSearchByBounds={setSearchByBounds}
          clearSelectedFilters={false}
          className="filters__child_wrapper  search-select bg-light rounded-lg text-primary"
        />
        <ListedUnder
          handleFilters={handleFilters}
          filterData={filterData}
          className="filters__child_wrapper  search-select bg-light rounded-lg text-primary"
        />

        <Category
          handleFilters={handleFilters}
          filterData={filterData}
          className="filters__child_wrapper search-select bg-light rounded-lg text-primary"
        />

        {filterData?.IsBuilding ? (
          <PricePerSquareMeter
            handleFilters={handleFilters}
            filterData={filterData}
            className="filters__child_wrapper "
          />
        ) : (
          <Price
            handleFilters={handleFilters}
            filterData={filterData}
            className="filters__child_wrapper "
          />
        )}

        <div className="filters_actions">
          <ShowMoreFilters handleFilters={handleFilters} filterData={filterData} />
          {/* <div className="filters_save">
          <SaveUserFiltersButton />
        </div> */}
          {/* {!autoSearch && (
          <Button
            className="btn d-flex justify-content-center align-items-center animated"
            onClick={handleSearch}
            color="primary"
          >
            <span className="mr-3">Търси</span>
            <span>
              <FaSearch />
            </span>
          </Button>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default Filters;
