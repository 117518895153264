import React from 'react';
import { useSelector } from 'react-redux';
import RangeFilters from '../Common/RangeFilters';

import { selectAdditionalPropertiesFilters } from '@a1-ict/fsbo/searchSlice/selectors';
import InputRangeFilters from '../Common/InputRangeFilters';

const Area = ({ handleFilters, filterData, ...rest }) => {
  const additional = useSelector(selectAdditionalPropertiesFilters);

  return (
    // <RangeFilters
    //   handleFilters={handleFilters}
    //   filterData={filterData}
    //   gte={additional.min_area}
    //   lte={additional.max_area}
    //   gteName="areaGte"
    //   lteName="areaLte"
    //   label="Площ"
    //   suffix={additional.area_unit}
    // />
    <InputRangeFilters
      handleFilters={handleFilters}
      filterData={filterData}
      gte={additional.min_area}
      lte={additional.max_area}
      gteName="areaGte"
      lteName="areaLte"
      label="Площ"
      suffix={additional.area_unit}
      {...rest}
    ></InputRangeFilters>
  );
};

export default Area;
