import React from 'react';
import { useSelector } from 'react-redux';
import { MultiSelectLimitedChips } from '../../Common';

import { selectEnabledCategories } from '@a1-ict/fsbo/searchSlice/selectors';
import {
  selectPropertyCategories,
  selectBuildingCategories,
} from '@a1-ict/fsbo/propertySlice/selectors';

const Category = ({ handleFilters, filterData, ...rest }) => {
  let categoryIds = filterData.Categories;

  if (categoryIds && typeof categoryIds !== 'object') {
    categoryIds = [categoryIds];
  }

  const categoryOptions = useSelector(selectPropertyCategories);
  const buildingOptions = useSelector(selectBuildingCategories);

  const typeOptions = [...(!filterData.IsBuilding ? categoryOptions : []), ...buildingOptions];

  let selectedOptions = null;

  const categoryValue =
    categoryIds &&
    categoryIds.map((key) => {
      return typeOptions.find((opt) => opt.id === key);
    });

  const enabledOptions = useSelector(selectEnabledCategories);

  const handleChange = (options) => {
    selectedOptions =
      options &&
      options.map((opt) => {
        return opt.id;
      });

    if (options.length === 0) {
      handleFilters({}, true);
    } else {
      handleFilters({ Categories: selectedOptions });
    }
  };

  const handleIsOptionDisabled = (option) => {
    return enabledOptions.length > 0 && !enabledOptions.includes(option.id);
  };

  return (
    <MultiSelectLimitedChips
      options={typeOptions}
      onChange={handleChange}
      value={categoryValue}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      placeholder={!filterData.IsBuilding ? 'Вид имот' : 'Вид сграда'}
      isOptionDisabled={handleIsOptionDisabled}
      closeMenuOnScroll
      isSearchable={false}
      hideSelectedOptions={false}
      maxChips={2}
      {...rest}
    />
  );
};

export default Category;
