import React from 'react';
import { useSelector } from 'react-redux';
import { selectAdditionalPropertiesFilters } from '@a1-ict/fsbo/searchSlice/selectors';
import InputRangeFilters from '../Common/InputRangeFilters';
import { TooltipButton } from '../../Common';

const PricePerSquareMeter = ({ handleFilters, filterData }) => {
  const additional = useSelector(selectAdditionalPropertiesFilters);
  let label = 'Цена';
  if (filterData.PricePerSquareMeterLte && filterData.PricePerSquareMeterGte) {
    label = `${filterData.PricePerSquareMeterGte} ${additional.currency} - ${filterData.PricePerSquareMeterLte} ${additional.currency}`;
  } else if (filterData.PricePerSquareMeterLte && !filterData.PricePerSquareMeterGte) {
    label = `До ${filterData.PricePerSquareMeterLte} ${additional.currency}`;
  } else if (!filterData.PricePerSquareMeterLte && filterData.PricePerSquareMeterGte) {
    label = `От ${filterData.PricePerSquareMeterGte} ${additional.currency} `;
  }

  return (
    <TooltipButton
      name="filter-price"
      position="bottom"
      trigger="legacy"
      containerClassName={`filters_price_container`}
      button={
        <button id="filter-price" className={`btn button outlined w-100`}>
          <span className="filters_price">{label}</span>
        </button>
      }
    >
      <InputRangeFilters
        handleFilters={handleFilters}
        filterData={filterData}
        gte={additional.min_price}
        lte={additional.max_price}
        step={5000}
        gteName="pricePerSquareMeterGte"
        lteName="pricePerSquareMeterLte"
        label="Цена на имот (в евро)"
        suffix={additional.currency}
      />
      {(!filterData.Categories || !filterData.listedUnder) && (
        <div className="filters_sliderinfo">
          <p className="font-weight-normal">Моля изберете "Вид имот" и "Вид сделка"</p>
        </div>
      )}
    </TooltipButton>
  );
};

export default PricePerSquareMeter;
