import React, { useEffect, useState, useRef, forwardRef, useLayoutEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api';

import { Link } from 'gatsby';

import { IoIosPin } from '@react-icons/all-files/io/IoIosPin';
import { useLocation } from '@a1-ict/gatsby/utils/locationContext';

import MapControl from './MapControl';
import buildingPin from '../../images/building.png';
import heart from '../../images/heartPin.png';
import NoImage from '../../images/No-Image-Icon.png';
import { settings } from '../../settings';
import { thousandsSeparator } from '../Properties/helpers';
import InfoWindowWrapper from './InfoWindowWrapper';

const containerStyle = {
  height: '100%',
};

const libraries = ['drawing'];

const getMarkerColor = (property) => {
  if (property.is_golden) return '#FFD744';
  if (property.is_silver) return '#D3D3D3';
  return '#1877F2';
};

const Map = forwardRef(
  (
    {
      locations = [],
      markerId = '',
      hoveredLocation = null,
      panTo = null,
      children,
      addedMarker = null,
      addMarker = null,
      panToHovered = false,
      setMapInstance = null,
      handleSearchByBounds = () => {},
      showLoader = () => {},
      resetProperties = () => {},
      boundSearchOption = false,
      preserveOnNavigate = false,
      mapLoaded = () => {}, //Needed to prepare printable map
      readOnlyInfoWindow = true,
      predefinedCoords = null,
      triggerSearch = false,
      setTriggerSearch = () => {},
      readOnly = false,
    },
    ref
  ) => {
    const mapConfig = {
      center: { lat: 42.6978, lng: 23.3228 },
      zoom: 14,
    };

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [center, setCenter] = useState(mapConfig.center);
    const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: 'AIzaSyAu59xW8cimJTl3u6ie1j04LR1koCD67LU',
      libraries,
    });

    const changableOptions = {
      zoomControl: !readOnly,
      scaleControl: !readOnly,
      scrollwheel: !readOnly,
      disableDoubleClickZoom: readOnly,
      draggable: !readOnly,
      mapTypeControl: !readOnly,
      fullscreenControl: !readOnly,
      rotateControl: !readOnly,
      streetViewControl: !readOnly,
      clickableIcons: !readOnly,
    };

    const mapOptions = {
      ...(readOnly ? changableOptions : {}),
      styles: [
        {
          elementType: 'labels',
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
    };

    const curLocation = useLocation();
    const mapRef = useRef(null);

    useEffect(() => {
      if (mapRef.current && !readOnly) mapRef.current.setOptions(changableOptions);
    }, [readOnly]);

    useEffect(() => {
      if (!!curLocation && !curLocation.hasOwnProperty('permission') && !hoveredLocation) {
        setCenter(curLocation);
      }

      if (
        !!curLocation &&
        !curLocation.hasOwnProperty('permission') &&
        mapRef.current &&
        !hoveredLocation
      ) {
        mapRef.current.panTo(curLocation);
        // setInitialLoad(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curLocation]);

    useEffect(() => {
      if (hoveredLocation) {
        handleMarkerClicked(hoveredLocation);
      }
    }, [hoveredLocation]);

    useEffect(() => {
      if (panTo) mapRef?.current?.panTo(panTo);
    }, [panTo]);

    useEffect(() => {
      if (addedMarker) {
        if (mapRef?.current) {
          mapRef.current.panTo({
            lat: addedMarker.getPosition().lat(),
            lng: addedMarker.getPosition().lng(),
          });
        }
        addedMarker.addListener('click', () => {
          addMarker(addedMarker);
        });
      }
    }, [addedMarker, addMarker]);

    const onLoad = React.useCallback(function callback(map) {
      mapRef.current = map;

      if (boundSearchOption) showLoader(true);

      if (setMapInstance) {
        setMapInstance(map);
      }
      if (hoveredLocation && panToHovered) {
        handleMarkerClicked(hoveredLocation);
      } else if (predefinedCoords) {
        map.setCenter(predefinedCoords);
        map.panTo(predefinedCoords);
        map.setZoom(
          predefinedCoords.zoomLevel
            ? predefinedCoords.zoomLevel
            : Math.log2(
                (38000 * Math.cos((predefinedCoords.lat * Math.PI) / 180)) / predefinedCoords.radius
              ) + 1.2
        );
      } else if (!!curLocation && !curLocation.hasOwnProperty('permission') && map) {
        map.setCenter(curLocation);
        map.panTo(curLocation);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
      if (boundSearchOption) resetProperties();
    }, []);

    // useLayoutEffect(() => {
    //   if (settings.storage.isBrowser) {
    //     const lastHoveredLocation = window.sessionStorage.getItem('@@hovered/location');
    //     console.log(Object.keys(lastHoveredLocation), 'hovered')
    //     if (Object.keys(lastHoveredLocation).length > 0) {
    //       const coordinates = JSON.parse(lastHoveredLocation);
    //       setCenter(coordinates);
    //     }
    //   }
    // }, []);

    const handleMarkerClicked = (loc) => {
      setSelectedProperty(loc.id);

      // if (settings.storage.isBrowser && preserveOnNavigate) {
      //   window.sessionStorage.setItem(
      //     '@@hovered/location',
      //     JSON.stringify({ lat: loc.latitude, lng: loc.longitude })
      //   );
      // }

      if (mapRef?.current) {
        // mapRef.current.setZoom(19);
        mapRef.current.panTo(loc.cordinates);
        if (loc.radius && boundSearchOption) {
          let circ = new google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0,
            radius: loc.radius * 250,
            map: mapRef.current,
            center: loc.cordinates,
          });
          mapRef.current.fitBounds(circ.getBounds());
        }

        // if (loc.zoom) mapRef.current.setZoom(loc.zoom);
      }
      setIsInfoWindowOpen(true);
    };

    const handleDragEnd = () => {
      // if (!!curLocation && !curLocation.hasOwnProperty('permission'))
      showLoader(true);
      // if (isSearchByBoundsActive) {
      if (mapRef?.current) {
        const boundaries = mapRef.current.getBounds();
        const centreLat = mapRef.current.getCenter().lat();
        const centerLng = mapRef.current.getCenter().lng();
        const center = {
          CentreLongitude: centerLng,
          CentreLatitude: centreLat,
          zoomLevel: mapRef.current.getZoom(),
        };

        handleSearchByBounds(boundaries, center);
      }
      // }
      // setIsDragging(false);
    };

    const handleNewBounds = () => {
      if (triggerSearch) {
        handleDragEnd();
        setTriggerSearch(false);
      }
    };

    const options = {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    };

    let instanceObj = {};

    const onTilesLoaded = () => {
      if (boundSearchOption && !initialLoad && mapRef?.current && !hoveredLocation?.id) {
        const boundaries = mapRef.current.getBounds();

        handleSearchByBounds(boundaries);
        setInitialLoad(true);

        showLoader(false);
      }
    };
    return (
      <>
        <div className="map-content_container_map h-100">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={mapConfig.zoom}
              onLoad={onLoad}
              onTilesLoaded={onTilesLoaded}
              onUnmount={onUnmount}
              onZoomChanged={handleDragEnd}
              onDragEnd={handleDragEnd}
              onBoundsChanged={handleNewBounds}
              onClick={() => {
                return addMarker ? addMarker(null) : null;
              }}
              options={mapOptions}
            >
              <MarkerClusterer options={options} maxZoom={18}>
                {(clusterer) =>
                  locations.map((loc) => {
                    let sum = loc.cordinates.lat + loc.cordinates.lng;
                    instanceObj[sum] = (instanceObj[sum] || 0) + 1;

                    const icon = {
                      path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z',
                      fillColor: getMarkerColor(loc),
                      fillOpacity: 1,
                      anchor: new window.google.maps.Point(
                        192, // width
                        512 // height
                      ),
                      strokeColor: 'black',
                      strokeWeight: 1,
                      scale: 0.055,
                      rotation: 360 - (instanceObj[sum] === 1 ? 0 : instanceObj[sum] * 30),
                    };
                    return (
                      <Marker
                        title={`${loc.id}`}
                        key={!!markerId ? markerId : `${loc.id}${sum}`}
                        position={loc.cordinates}
                        clusterer={clusterer}
                        onClick={() => {
                          handleMarkerClicked(loc);
                        }}
                        options={{
                          icon: loc.is_favorite ? heart : loc.is_building ? buildingPin : icon,
                        }}
                      >
                        {selectedProperty === loc.id && isInfoWindowOpen && (
                          <InfoWindow
                            onDomReady={() => {
                              mapLoaded();
                            }}
                            position={loc.cordinates}
                            onCloseClick={() => setIsInfoWindowOpen(false)}
                          >
                            {
                              <InfoWindowWrapper
                                wrapper={readOnlyInfoWindow ? React.Fragment : Link}
                                withProps={readOnlyInfoWindow ? false : true}
                                to={`/property/${loc.id}`}
                                className="animated text-dark no-outline"
                              >
                                {' '}
                                <div
                                  style={{ position: 'relative' }}
                                  className="map_propertyInfo d-flex flex-row text-dark"
                                >
                                  {loc.boosted && (
                                    <div
                                      className={`property_badge ${
                                        loc.boosted === 'gold' ? 'gold' : 'silver'
                                      }`}
                                    >
                                      {loc.boosted === 'gold' ? (
                                        <span style={{ color: 'black' }}>GOLD</span>
                                      ) : (
                                        <span style={{ color: 'black' }}>SILVER</span>
                                      )}
                                    </div>
                                  )}
                                  <img
                                    height={85}
                                    width={115}
                                    src={
                                      loc.images && loc.images[0]
                                        ? `${process.env.GATSBY_BACKEND}${loc.images[0].url}`
                                        : NoImage
                                    }
                                    alt="a property"
                                    layout="fixed"
                                    className="image_container mr-2 animated_image"
                                  />
                                  <div className="">
                                    <h6 className="pt-1">{loc.title} </h6>
                                    <div className="d-flex flex-column">
                                      {loc.price && (
                                        <span className="">
                                          {' '}
                                          {thousandsSeparator(loc.price)} EUR
                                        </span>
                                      )}
                                      {loc.square_price && (
                                        <span>
                                          {thousandsSeparator(loc.square_price.toFixed(2))}{' '}
                                          EUR/кв.м.
                                        </span>
                                      )}
                                      {loc.is_price_negotiated && <span>По договаряне</span>}
                                    </div>
                                  </div>
                                </div>
                              </InfoWindowWrapper>
                            }
                          </InfoWindow>
                        )}
                      </Marker>
                    );
                  })
                }
              </MarkerClusterer>
              {curLocation && !curLocation.hasOwnProperty('permission') && (
                <Marker
                  label="Аз"
                  animation={2}
                  position={curLocation}
                  onClick={() => {
                    mapRef.current.panTo(curLocation);
                  }}
                ></Marker>
              )}

              {children}
              {/* {boundSearchOption && !isSearchByBoundsActive && (
                <MapControl
                  position={window.google.maps.ControlPosition.TOP_CENTER}
                  title="Търсене по картата."
                >
                  <div className="map-boundSearch">
                    <button
                      className="btn bg-primary"
                      onClick={() => {
                        setBoundsSearchActive(true);
                        const boundaries = mapRef.current.getBounds();
                        handleSearchByBounds(boundaries);
                      }}
                    >
                      <span style={{ color: 'white' }}>Търси по карта</span>
                    </button>
                  </div>
                </MapControl>
              )} */}
              {!readOnly && (
                <MapControl
                  position={window.google.maps.ControlPosition.RIGHT_BOTTOM}
                  title="Центриране на картата към текущото Ви местоположение."
                >
                  <div
                    onClick={() => {
                      if (curLocation && !curLocation.hasOwnProperty('permission')) {
                        mapRef.current.setZoom(14);
                        mapRef.current.panTo(curLocation);
                        handleDragEnd();
                      } else {
                        alert(
                          'Здравейте! За да предоставим тази услуга, моля разрешете да Ви вземем кординатите .'
                        );
                      }
                    }}
                    onKeyDown={() => {
                      if (curLocation && !curLocation.hasOwnProperty('permission')) {
                        mapRef.current.setZoom(14);
                        mapRef.current.panTo(curLocation);
                        handleDragEnd();
                      }
                    }}
                    role="button"
                    tabIndex="0"
                    className="map-centerButton"
                  >
                    <IoIosPin size={40}></IoIosPin>
                  </div>
                </MapControl>
              )}
            </GoogleMap>
          ) : (
            <h3 className="text-center h1 mt-5">Картата все още не може да зареди</h3>
          )}
        </div>
      </>
    );
  }
);

export default Map;
