import React from 'react';
import CheckboxFilters from '../Common/CheckboxFilters';

const BusinessTypes = ({ handleFilters, ...rest }) => {
  return (
    <CheckboxFilters
      handleFilters={handleFilters}
      label="Подкатегории"
      resource="businessTypes"
      {...rest}
    />
  );
};

export default BusinessTypes;
