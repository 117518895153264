import React from 'react';
import CheckboxFilters from '../Common/CheckboxFilters';

const PropertiesInBuilding = ({ handleFilters, ...rest }) => {
  return (
    <CheckboxFilters
      handleFilters={handleFilters}
      label="Видове имоти в сградата"
      resource="propertiesInBuilding"
      {...rest}
    />
  );
};

export default PropertiesInBuilding;
