import React from 'react';
import { useSelector } from 'react-redux';
import { Label, Input } from 'reactstrap';

import {
  selectFilterByResource,
  selectEnabledFilters,
  selectQueryDataByResource,
} from '@a1-ict/fsbo/searchSlice/selectors';

const CheckboxFilters = ({ handleFilters, resource, filterOptionsCriteria, className }) => {
  const { text, items } = useSelector((state) =>
    selectFilterByResource(state, resource, filterOptionsCriteria)
  );
  const enabledFilters = useSelector(selectEnabledFilters);

  const selectedOptions = useSelector((state) => selectQueryDataByResource(state, resource));

  return (
    enabledFilters.includes(resource) && (
      <div className={className}>
        <div className="filters_sliderinfo">
          <p className="font-weight-normal">
            <b className="mr-1">{text}</b>
          </p>
        </div>
        <div className="filters-checkbox">
          {items &&
            [...items]
              .sort((a, b) => a.id - b.id)
              .map((option) => (
                <div className="filters-checkbox-container" key={option.id}>
                  <Label check className="filters-checkbox-label">
                    <Input
                      checked={selectedOptions.indexOf(parseInt(option.id)) > -1}
                      onChange={(opt) => {
                        if (opt.target.checked) {
                          selectedOptions.push(parseInt(opt.target.id));
                        } else {
                          const elem = selectedOptions.indexOf(parseInt(opt.target.id));
                          selectedOptions.splice(elem, 1);
                        }
                        handleFilters({ [resource]: selectedOptions });
                      }}
                      type="checkbox"
                      className="filters-checkbox"
                      id={option.id}
                    />
                    {option.text}
                  </Label>
                </div>
              ))}
        </div>
      </div>
    )
  );
};

export default CheckboxFilters;
