import React from 'react';
import InputRangeFilters from '../Common/InputRangeFilters';

const BuildDateRange = ({ handleFilters, filterData, ...rest }) => {
  return (
    <InputRangeFilters
      handleFilters={handleFilters}
      filterData={filterData}
      gte={1950}
      lte={2050}
      gteName="yearBuiltGte"
      lteName="yearBuiltLte"
      label="Година на строителство"
      {...rest}
    ></InputRangeFilters>
  );
};

export default BuildDateRange;
