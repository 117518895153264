import React from 'react';
import CheckboxFilters from '../Common/CheckboxFilters';

const ConstructionTypes = ({ handleFilters, ...rest }) => {
  return (
    <CheckboxFilters
      handleFilters={handleFilters}
      label="Вид строителство"
      resource="constructionTypes"
      {...rest}
    />
  );
};

export default ConstructionTypes;
