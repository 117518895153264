import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import {
  selectFilterByResource,
  selectEnabledFilters,
  selectQueryDataByResource,
} from '@a1-ict/fsbo/searchSlice/selectors';

const SelectFilters = ({ handleFilters, resource, filterOptionsCriteria, className }) => {
  const data = useSelector((state) =>
    selectFilterByResource(state, resource, filterOptionsCriteria)
  );

  const selectedOptions = useSelector((state) => selectQueryDataByResource(state, resource));

  const filterValue = selectedOptions.map((id) => {
    return data?.items.find((opt) => opt.id === id);
  });

  const handleChange = (option) => {
    handleFilters({ [resource]: option.id });
  };

  return (
    <Select
      options={data?.items}
      onChange={handleChange}
      value={filterValue}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      placeholder={data?.text}
      closeMenuOnScroll
      className={className}
    />
  );
};

export default SelectFilters;
