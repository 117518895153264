import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { Input } from 'reactstrap';
import { selectFilterByResource, selectEnabledFilters } from '@a1-ict/fsbo/searchSlice/selectors';

const InputRangeFilters = ({
  handleFilters,
  filterData,
  gte,
  lte,
  gteName,
  lteName,
  label,
  suffix = '',
  className,
}) => {
  const enabledFilters = useSelector(selectEnabledFilters);

  const filterGte = useSelector((state) => selectFilterByResource(state, gteName));
  const filterLte = useSelector((state) => selectFilterByResource(state, lteName));

  const handleInput = (e) => {
    const { name, value } = e.target;

    let data = {};
    let regex = /^[0-9]+$|^$/;

    if (!value.match(regex)) {
      return;
    }

    if (lte && gte && Number(value) > lte) {
      return;
    }

    if (name === 'gte') {
      data[filterGte.name] = value;
    }
    if (name === 'lte') {
      data[filterLte.name] = value;
    }

    handleFilters(data);
  };

  return (
    enabledFilters.includes(gteName) &&
    enabledFilters.includes(lteName) && (
      <div className={`mb-3 ${className}`}>
        <div className="filters_sliderinfo mb-0">
          <p className="font-weight-normal mb-1">
            <b className="mr-1">{label}</b>
          </p>
        </div>
        <div className="d-flex align-items-end filters__input_label">
          <span className="mr-1 ">от</span>
          <Input
            id="gte"
            type="text"
            name="gte"
            bsSize="sm"
            className="form-control-md mr-2 filters__input"
            value={filterData[gteName] || ''}
            onChange={handleInput}
            data-type="number"
          />
          <span className="mr-1">до</span>
          <Input
            id="lte"
            type="text"
            name="lte"
            bsSize="sm"
            className="form-control-md filters__input"
            value={filterData[lteName] || ''}
            onChange={handleInput}
            data-type="number"
          />
        </div>
      </div>
    )
  );
};

export default InputRangeFilters;
