import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import { selectFilterByResource, selectEnabledFilters } from '@a1-ict/fsbo/searchSlice/selectors';

const RangeFilters = ({
  handleFilters,
  filterData,
  gte,
  lte,
  step = 1,
  gteName,
  lteName,
  label,
  suffix = '',
}) => {
  const [range, setRange] = useState([gte, lte]);
  const enabledFilters = useSelector(selectEnabledFilters);

  useEffect(() => {
    if (gte && lte) {
      setRange([gte, lte]);
    }
  }, [gte, lte]);

  useEffect(() => {
    if (filterData[gteName] && filterData[lteName]) {
      setRange([filterData[gteName], filterData[lteName]]);
    }
  }, [filterData[gteName], filterData[lteName]]);

  const filterGte = useSelector((state) => selectFilterByResource(state, gteName));
  const filterLte = useSelector((state) => selectFilterByResource(state, lteName));

  return (
    enabledFilters.includes(gteName) &&
    enabledFilters.includes(lteName) && (
      <>
        <div className="filters_sliderinfo">
          <p className="font-weight-normal">
            <b className="mr-1">{label}:</b> {range[0]}
            {suffix} до {range[1]}
            {suffix}{' '}
          </p>
        </div>
        <Range
          min={gte}
          max={lte}
          step={step}
          allowCross={false}
          onChange={setRange}
          onAfterChange={() => {
            const data = {};

            data[filterGte.name] = range[0];
            data[filterLte.name] = range[1];

            handleFilters(data);
          }}
          value={range}
          className="filters_slider"
        />
      </>
    )
  );
};

export default RangeFilters;
