import React from 'react';
import MultiSelectFilters from '../Common/MultiSelectFilters';

const Act = ({ handleFilters, ...rest }) => {
  return (
    <MultiSelectFilters handleFilters={handleFilters} resource="acts" {...rest} maxChips={3} />
  );
};

export default Act;
