import React from 'react';
import Navbar from '../navigation/Navbar';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { ToastContainer } from 'react-toastify';

const MapPropertyLayout = ({ children, showMenu = true }) => {
  const rightDrawer = useDialog('rightDrawer');
  const leftDrawer = useDialog('leftDrawer');

  return (
    <>
      <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
        <Navbar bg="white" showMenu={showMenu}></Navbar>
        <div
          className={`content ${leftDrawer.isOpen ? ' moveToRight' : rightDrawer.isOpen ? ' moveToLeft' : ' '
            }`}
        >
          {children}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MapPropertyLayout;
