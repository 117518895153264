import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';

import { TooltipButton } from '../../Common';
import Act from './Act';
import Area from './Area';
import BuildDateRange from './BuildDateRange';
import BusinessTypes from './BusinessTypes';
import ConstructionTypes from './ConstructionTypes';
import FarmlandTypes from './FarmlandTypes';
import Specifications from './Specifications';
import Floor from './Floor';
import PropertiesInBuilding from './PropertiesInBuilding';

const ShowMoreFilters = ({ filterData, handleFilters }) => {
  const [showFilters, setShowFilters] = useState(false);
  return (
    <TooltipButton
      name="more-filters"
      position="bottom"
      className="primary"
      containerClassName="filters_additional"
      trigger="legacy"
      button={
        <Button
          id="more-filters"
          className="btn d-flex justify-content-center align-items-center animated"
          onClick={() => setShowFilters(!showFilters)}
          color="primary"
        >
          <span className="mr-4">Филтри</span>
          <span>{showFilters ? <FaChevronUp></FaChevronUp> : <FaChevronDown></FaChevronDown>}</span>
        </Button>
      }
    >
      {(!filterData.Categories || !filterData.listedUnder) && (
        <div className="filters_sliderinfo">
          <p className="font-weight-normal">
            Моля изберете "{!filterData.IsBuilding ? 'Вид имот' : 'Вид сграда'}" и "Вид сделка"
          </p>
        </div>
      )}
      <div className="filters filters_additional_layout">
        <PropertiesInBuilding
          handleFilters={handleFilters}
          filterData={filterData}
          className="filters__child filters__child_lg  search-select rounded-lg text-primary"
        />
        <Area
          handleFilters={handleFilters}
          filterData={filterData}
          className="filters__child search-select rounded-lg text-primary"
        />
        <BuildDateRange
          handleFilters={handleFilters}
          filterData={filterData}
          className="filters__child  rounded-lg text-primary"
        />
        <Floor
          handleFilters={handleFilters}
          className="filters__child filters__child_lg  search-select rounded-lg text-primary"
        />
        <Act
          handleFilters={handleFilters}
          className="filters__child filters__child_lg  search-select rounded-lg text-primary"
        />
        <Specifications
          handleFilters={handleFilters}
          className="filters__child filters__child_lg  search-select rounded-lg text-primary "
        />
        {/* <PropertiesInBuilding
          handleFilters={handleFilters}
          className="filters__child search-select rounded-lg text-primary mt-2"
        /> */}
        <BusinessTypes
          handleFilters={handleFilters}
          className="filters__child search-select rounded-lg text-primary"
        />
        <FarmlandTypes
          handleFilters={handleFilters}
          className="filters__child search-select rounded-lg text-primary"
        />
        <ConstructionTypes
          handleFilters={handleFilters}
          className="filters__child  search-select rounded-lg text-primary"
        />
      </div>
    </TooltipButton>
  );
};

export default ShowMoreFilters;
