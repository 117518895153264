import React from 'react';
import { useSelector } from 'react-redux';
import { MultiSelectLimitedChips } from '../../Common';

import {
  selectFilterByResource,
  selectEnabledFilters,
  selectQueryDataByResource,
} from '@a1-ict/fsbo/searchSlice/selectors';

const MultiSelectFilters = ({
  handleFilters,
  resource,
  filterOptionsCriteria,
  maxChips = '5',
  className,
}) => {
  const data = useSelector((state) =>
    selectFilterByResource(state, resource, filterOptionsCriteria)
  );
  const enabledFilters = useSelector(selectEnabledFilters);
  const selectedOptions = useSelector((state) => selectQueryDataByResource(state, resource));

  const filterValue = selectedOptions.map((id) => {
    return data.items.find((opt) => opt.id === id);
  });

  const handleChange = (option) => {
    handleFilters({ [resource]: option.map((x) => x.id) });
  };

  return (
    enabledFilters.includes(resource) && (
      <MultiSelectLimitedChips
        options={data.items}
        onChange={handleChange}
        value={filterValue}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.id}
        placeholder={data.text}
        closeMenuOnScroll
        maxChips={maxChips}
        className={className}
      />
    )
  );
};

export default MultiSelectFilters;
