import React from 'react';
import MultiSelectFilters from '../Common/MultiSelectFilters';

const Specifications = ({ handleFilters, ...rest }) => {
  return (
    <MultiSelectFilters
      handleFilters={handleFilters}
      resource="features"
      filterOptionsCriteria="features"
      maxChips={3}
      {...rest}
    />
  );
};

export default Specifications;
